import { Package } from './package';
export const packages: Package[] = [
    {
      name: 'Litigation Support', price: 550, code: "R3",  ubo_add_on: 1499, features:
        ['The Litigation Support package is designed for lawyers seeking to uncover information not available in regular legal search engines. While court records are not included, we provide all critical issues found in publicly available information on the target.']
    },
    { name: 'Third Party Risk', price: 500, code: "R2", features: ['The Third Party Risk package provides comprehensive OSINT and Media Intelligence sources for thorough analysis. For company targets, this includes detailed insights into the company and its Board of Directors (BoD). For individual targets, the package covers the individual and their affiliated companies. Additionally, the package features a sophisticated AI-powered Reputational Meter to conduct precise risk assessments, ensuring a clear understanding of potential risks associated with the target.'], ubo_add_on: 1499 },
    { name: 'Sanctions Evasion Compliance', price: 750, code: "R5", features: ['TevunAI discovers any potential sanctions evasion. With deep expertise in identifying violations, our AI-driven solutions provide detailed insights into complex commercial transactions, bill of lading, shipments, and more, detecting also indirect sanctions evasion through triangulations and satellite countries.'], ubo_add_on: false },


  ]