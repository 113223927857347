import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appExpiryDateFormatter]',
  standalone: true
})
export class ExpiryDateFormatterDirective {

  constructor(private el: ElementRef, private control: NgControl) {
    console.log('ExpiryDateFormatterDirective')
   }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    let input = this.el.nativeElement.value;

    console.log('input', input)

    // Remove all non-digit characters
    input = input.replace(/\D/g, '');

    if (input.length <= 2) {
      if (this.control.control) {
        this.control.control.setValue(input);
      }
    } else if (input.length <= 4) {
      input = input.replace(/(\d{2})(\d{0,2})/, '$1/$2');
      if (this.control.control) {
        this.control.control.setValue(input);
      }
    } else {
      input = input.replace(/(\d{2})(\d{2})(\d{0,4})/, '$1/$2/$3');
      if (this.control.control) {
        this.control.control.setValue(input);
      }
    }
  }
}
