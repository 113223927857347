<div class="report-container">

  <!-- Current Business Affiliations -->
  <div class="section border">
    <div class="bg-blue-950 text-white text-center">
      <h1 class="w-full m-0 p-1">Current Business Affiliations</h1>
    </div>
    <div *reportField="'businessAffiliations.current'; let value">
      <div *ngFor="let current of value" class="p-2 border">
        <div class="text-xl font-bold mb-2 bg-bgColor p-1">{{current.companyName}}</div>
        <table class="company-info">
          <tr>
            <td class="label">Status</td>
            <td>{{current.status}}</td>
            <td class="label">CCIAA/REA</td>
            <td>{{current.cciaaRea}}</td>
          </tr>
          <tr>
            <td class="label">Legal Address</td>
            <td>{{current.address?.simpleValue}}</td>
            <td class="label">Activity</td>
            <td>{{current.activity?.description}}</td>
          </tr>
          <tr>
            <td class="label">Tax code</td>
            <td>{{current.taxCode}}</td>
            <td class="label">Legal Form</td>
            <td>{{current.legalForm?.description}}</td>
          </tr>
          <tr>
            <td class="label">Activity Start Date</td>
            <td>{{current.activityStartDate | date}}</td>
            <td class="label">Legal Email</td>
            <td>{{current.legalEmail}}</td>
          </tr>
        </table>

        <div class="charges-section" *reportField="{value: current.positions[0], path: ''}">
          <div class="mt-3 text-base font-semibold border-b mb-1 text-gray-700">Current Charges</div>
          <ul>
            <li *ngFor="let position of getCurrentCharges(current?.positions)">
              <div class="text-base text-semibold">{{position.positionName}}</div>
              <p *ngIf="position.chargesProceedingDate" class="text-sm text-gray-600">
                <span class="label">Proceeding date</span>
                {{position.chargesProceedingDate | date}}
              </p>
            </li>
          </ul>
        </div>

        <div class="charges-section" *ngIf="getCeasedCharges(current.positions).length > 0">
          <div class="mt-3 text-base font-semibold border-b mb-1 text-gray-700">Ceased Charges</div>
          <ul class="space-y-2 ">
            <li *ngFor="let position of getCeasedCharges(current?.positions)" class="borer-b">
              <div class="text-base text-semibold">{{position.positionDetail}}</div>
              <div *reportField="{value: position.registrationDate, path: 'position.registrationDate'}, let value"
                class="text-sm text-gray-600">
                <span class="label">Registration Date</span>
                {{value | date}}
              </div>
              <div *reportField="{value: position.registrationDate, path: 'position.protocolNumber'}; let value"
                class="text-sm text-gray-600">
                <span class="label">Protocol Number</span>
                {{value}}
              </div>
              <div *reportField="{value: position.protocolDate, path: 'position.protocolDate'}; let value"
                class="text-sm text-gray-600">
                <span class="label">Protocol Date</span>
                {{value | date}}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Past Business Affiliations -->
  <div class="section past-affiliations">
    <div class="bg-blue-950 text-white text-center">
      <h1 class="w-full m-0 p-1">Past Business Affiliations</h1>
    </div>
    <div *reportField="'businessAffiliations.previous'; let previous">
      <div *reportField="'businessAffiliations.inactive'; let inactive">
        <div *ngFor="let past of getInactiveAndPreviousCharges(previous, inactive)" class="p-2 border">
          <div class="text-xl font-bold mb-2 bg-bgColor p-1">{{past.companyName}}</div>

          <table class="company-info">
            <tr>
              <td class="label">Status</td>
              <td>{{past?.status}}</td>
              <td class="label">CCIAA/REA</td>
              <td>{{past.cciaaRea}}</td>
            </tr>
            <tr>
              <td class="label">Legal Address</td>
              <td>{{past.address.simpleValue}}</td>
              <td class="label">Activity</td>
              <td>{{past?.activity?.description}}</td>
            </tr>
            <tr>
              <td class="label">Tax code</td>
              <td>{{past.additionalData?.taxCode}}</td>
              <td class="label">Legal Form</td>
              <td>{{past?.legalForm?.description}}</td>
            </tr>
            <tr>
              <td class="label">Activity Start Date:</td>
              <td>{{past?.activityStartDate | date}}</td>
              <td class="label">Legal Email:</td>
              <td>{{past?.legalEmail}}</td>
            </tr>
          </table>

          <div class="charges-section" *ngIf="getCeasedCharges(past.positions).length > 0">
            <h4>Ceased Charges</h4>
            <ul>
              <li *ngFor="let position of getCeasedCharges(past?.positions)">
                <strong>{{position.positionDetail}}</strong>
                <p *ngIf="position.registrationDate">
                  <span class="lable">Registration Date</span>
                  {{position.registrationDate | date}}
                </p>
                <p *ngIf="position?.protocolNumber">
                  Protocol Number: {{position.protocolNumber}}
                </p>
                <p *ngIf="position?.protocolDate">
                  Protocol Date: {{position.protocolDate | date}}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Business Affiliations From Essential Data. used when no credit safe data is available for individuals -->
  <div class="section past-affiliations" *reportField="'businessAffiliations.general'; let general">
    <div class="bg-blue-950 text-white text-center">
      <h1 class="w-full m-0 p-1">Business Affiliations</h1>
    </div>
    
      <div>
        <div *ngFor="let company of general" class="p-2 border">
          <div class="text-xl font-bold mb-2 bg-bgColor p-1">{{company.name}}</div>

          <table class="company-info">
            <tr>
              <td class="label">Address</td>
              <td>{{company.address}}</td>
            </tr>
            <tr>
              <td class="label">Country</td>
              <td>{{company.country}}</td>
            </tr>
          </table>
        </div>
      </div>
  </div>
</div>