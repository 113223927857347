import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { NavbarComponent } from 'src/app/shared/components/navbar/navbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PostDiligenceDialogComponent } from 'src/app/shared/components/post-diligence-dialog/post-diligence-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { MatTabsModule } from '@angular/material/tabs';
import { FooterComponent } from "../../shared/components/footer/footer.component";


@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
  imports: [
    MatFormFieldModule,
    NavbarComponent,
    FooterComponent,
    MatButtonModule,
    MatSelectModule,
    MatSelectCountryModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    MatInputModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatTabsModule
  ],
  standalone: true
})
export class SearchFormComponent implements OnInit {
  dialog = inject(MatDialog);
  selectedTabIndex = 0;
  individualForm!: FormGroup;
  companyForm!: FormGroup;
  markets: string[] = [
    'Agriculture', 'Automotive', 'Aerospace', 'Banking and Finance', 'Biotechnology',
    'Construction', 'Consumer Goods', 'Defense', 'Education', 'Energy', 'Entertainment',
    'Environmental Services', 'Fashion', 'Food and Beverage', 'Healthcare', 'Hospitality',
    'Information Technology', 'Insurance', 'Legal Services', 'Logistics and Transportation',
    'Manufacturing', 'Media and Communications', 'Mining', 'Nonprofit and NGOs', 'Pharmaceuticals',
    'Professional Services', 'Real Estate', 'Retail', 'Telecommunications', 'Tourism and Travel',
    'Utilities', 'Wholesale Distribution', 'Other'
  ];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.individualForm = this.fb.group({
      fullName: ['', Validators.required],
      address: [''],
      country: ['', Validators.required],
    });

    this.companyForm = this.fb.group({
      companyName: ['', Validators.required],
      address: [''],
      country: ['', Validators.required],
      companySize: ['', Validators.required],
      market: ['', Validators.required]
    });
  }

  onTabChange(index: number): void {
    this.selectedTabIndex = index;
  }

  onSubmitIndividual(): void {
    if (this.individualForm.valid) {
      this.dialog.open(PostDiligenceDialogComponent, {
        data: {
          target: {...this.individualForm.value, country: this.individualForm.value.country.alpha2Code},
          type: 'INDIVIDUAL',
          name: this.individualForm.value.fullName,
          address: this.individualForm.value.address,
          country: this.individualForm.value.country.alpha2Code
        },
        width: '80%',
        height: '80%',
        maxWidth: '100vw',
        maxHeight: '100vh'
      });
    }
  }

  onSubmitCompany(): void {
    if (this.companyForm.valid) {
      this.dialog.open(PostDiligenceDialogComponent, {
        data: {
          target: {...this.companyForm.value, country: this.companyForm.value.country.alpha2Code},
          type: 'COMPANY',
          name: this.companyForm.value.companyName,
          country: this.companyForm.value.country.alpha2Code,
          address: this.companyForm.value.address
        },
        width: '80%',
        height: '80%',
        maxWidth: '100vw',
        maxHeight: '100vh'
      });
    }
  }

  resetIndividual(): void {
    this.individualForm.reset();
  }

  resetCompany(): void {
    this.companyForm.reset();
  }
}