import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';

@Component({
  standalone: true,
  selector: 'app-directors',
  templateUrl: './directors.component.html',
  styleUrls: ['./directors.component.scss'],
  imports: [CommonModule, MatExpansionModule, MatTabsModule, ReportFieldDirective]
})
export class DirectorsComponent {
  @Input()
  directors: any;

  @Input()
  shareholders: any = [];

  @Input() 
  printMode: boolean = false;

}
