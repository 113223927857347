        <div class="border-2 border-grey border-t-cyan-700  m-5">
            <div *reportField="'directors'; let directors">
            <div class="bg-blue-950 text-white text-center">
                <h1 class="w-full m-0 p-1">Current Covered Charges</h1>
            </div>
            <table class="mx-auto">
                <tr>
                    <th class="px-4 py-2">First Name</th>
                    <th class="px-4 py-2">Charges</th>
                </tr>
                <div>
                <ng-container *ngFor="let current of directors">
                    <tr class="mt-6">
                        <td class="border px-4 py-2">{{current?.name}}</td>
                        <td class="border px-4 py-2">
                            <div *ngFor="let charge of current?.positions">
                                {{charge.positionName}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <mat-accordion multi>
                                <mat-expansion-panel [expanded]="true">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>Details</mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <h2>Personal data</h2>
                                    <div class="flex justify-between">
                                        <div>
                                            <p *ngIf="current?.id"><span class="font-bold">Tax Code:</span>
                                                {{current?.id}}</p>
                                            <p *ngIf="current?.gender"><span class="font-bold">Gender:</span>
                                                {{current?.gender}}</p>
                                            <p *ngIf="current?.placeOfBirth"><span class="font-bold">Municipality of
                                                    Birth:</span> {{current?.placeOfBirth}}</p>
                                            <p *ngIf="current?.hasProtesti"><span class="font-bold">Protesti:</span>
                                                {{current?.additionalData?.hasProtesti}}</p>
                                        </div>
                                        <div>
                                            <p *ngIf="current?.dateOfBirth"><span class="font-bold">Date of
                                                    Birth:</span>
                                                {{current?.dateOfBirth | date}}</p>
                                            <p *ngIf="current?.placeOfBirth"><span class="font-bold">Province of
                                                    Birth:</span>
                                                {{current?.placeOfBirth}}</p>
                                            <p *ngIf="current?.hasSeverePrejudicials"><span
                                                    class="font-bold">Prejudicials:</span>
                                                {{current?.additionalData?.hasSeverePrejudicials}}</p>
                                        </div>
                                    </div>
                                    <h2>Roles</h2>
                                    <div *ngFor="let charge of current?.positions" class="my-8">
                                        <p *ngIf="charge?.positionName"><span class="font-bold">Charge:</span>
                                            {{charge?.positionName}}</p>
                                        <p *ngIf="charge?.additionalData?.designationDate"><span
                                                class="font-bold">Appointment
                                                Date:</span> {{charge?.additionalData?.designationDate}}</p>
                                        <p *ngIf="charge?.apptDurationType"><span class="font-bold">Appointment Duration
                                                Type:</span> {{charge?.apptDurationType}}</p>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </td>
                    </tr>
                </ng-container>
                </div>
            </table>
            </div>
        </div>
        <div class="border-2 border-grey border-t-cyan-700 m-5" *ngIf="shareholders">
            <div class="bg-blue-950 text-white text-center">
                <h1 class="w-full m-0 p-1">Shareholders</h1>
            </div>
            <div class="mx-auto w-full max-w-screen-lg">
                <table class="mt-4 w-full">
                    <tr>
                        <th class="px-4 py-2 font-bold text-left">Name</th>
                        <th class="px-4 py-2 font-bold text-left">Tax Code</th>
                        <th class="px-4 py-2 font-bold text-left">Type</th>
                        <th class="px-4 py-2 font-bold text-left">Value</th>
                        <th class="px-4 py-2 font-bold text-left">Percent</th>
                    </tr>
                    <tr *ngFor="let shareholder of shareholders">
                        <td class="px-4 py-2">{{shareholder?.name}}</td>
                        <td class="px-4 py-2">{{shareholder?.id}}</td>
                        <!-- TODO: verificare perche  shareClasses e un array e vedere come gestirlo-->
                        <td class="px-4 py-2">{{shareholder?.shareClasses[0]?.additionalData?.shareRights}}</td>
                        <td class="px-4 py-2">{{shareholder?.shareClasses[0]?.additionalData?.quotaValueOwned}}</td>
                        <td class="px-4 py-2">{{shareholder?.shareClasses[0]?.additionalData?.quotaValues[0]?.quotaPercentage}}%</td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="border-2 border-grey border-t-cyan-700 m-5" *reportField="'people'; let people">
            <div class="bg-blue-950 text-white text-center">
                <h1 class="w-full m-0 p-1">People</h1>
            </div>
            <div class="mx-auto w-full max-w-screen-lg">
                <table class="mt-4 w-full">
                    <tr>
                        <th class="px-4 py-2 font-bold text-left">Name</th>
                        <th class="px-4 py-2 font-bold text-left">Country</th>
                        <th class="px-4 py-2 font-bold text-left">Address</th>
                    </tr>
                    <tr *ngFor="let person of people">
                        <td class="px-4 py-2">{{person.name}}</td>
                        <td class="px-4 py-2">{{person.country}}</td>
                        <td class="px-4 py-2">{{person.address || 'Not provided'}}</td>
                    </tr>
                </table>
            </div>
        </div>