import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, map, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.authService.user$.pipe(
      take(1), // Take only the first emitted value, then complete
      map(user => {
        if (user?.email && user?.email_verified) {
          return true; // Allow navigation
        } 
        if(user?.email && !user?.email_verified){
          console.log(user)
          this.router.navigate(['notVerified']);
          return false;
        }
        this.authService.loginWithRedirect();// Redirect to login page if not authenticated or verified
        return false;
      })
    );
  }
}
