import { Directive, Input, TemplateRef, ViewContainerRef, OnChanges, SimpleChanges, Renderer2, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MyAuthService } from 'src/app/core/services/auth.service';
import { FieldInspectorComponent } from 'src/app/shared/components/field-inspector/field-inspector.component';
import { AdminInspectService } from '../services/inspect.service';
import { ReportDataService } from '../services/report-data.service';

@Directive({
  selector: '[reportField]',
  standalone: true
})
export class ReportFieldDirective implements OnChanges, OnInit, OnDestroy {
  @Input() reportField: string | { value: any; path: string } = '';

  private infoIcon: HTMLElement | null = null;
  private destroy$ = new Subject<void>();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private dialog: MatDialog,
    private authService: MyAuthService,
    private inspectService: AdminInspectService,
    private renderer: Renderer2,
    private el: ElementRef,
    private reportDataService: ReportDataService
  ) {}

  ngOnInit() {
    combineLatest([
      this.authService.isAdmin(),
      this.inspectService.showInspect$
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([isAdmin, showInspect]) => {
      this.updateView(isAdmin, showInspect);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['reportField']) {
      this.updateView();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getValueByPath(obj: any, path: string): any {
    return path.split('.').reduce((current: any, key: string) => {
      return current && current[key] !== undefined ? current[key] : undefined;
    }, obj);
  }

  private updateView(isAdmin: boolean = false, showInspect: boolean = false) {
    this.viewContainer.clear(); this.viewContainer.clear();
    let value: any;
    let sourcePath: string | null = null;
    let path: string = '';

    if (typeof this.reportField === 'string') {
      const result = this.findPath(this.reportDataService.transformer, this.reportDataService.resolvedData, this.reportField);
      value = result.value;
      sourcePath = result.sourcePath;
      path = this.reportField;
    } else {
      value = this.reportField.value;
      path = this.reportField.path;
    }
    
    // report field is a string that represents a path in the transformer object. get the value for that path
    // const resolvedValue = this.reportDataService.resolvedData[this.reportField];
    if ((value != undefined && value != null && (!Array.isArray(value) || value.length > 0)) || (isAdmin && showInspect)) {
      const view = this.viewContainer.createEmbeddedView(this.templateRef, {
        $implicit: value,
        sourcePath: sourcePath
      });
      
      if (view.rootNodes.length > 0 && isAdmin && showInspect) {
        const element = view.rootNodes[0];
        this.renderer.setStyle(element, 'position', 'relative');
        this.renderer.setStyle(element, 'border', '1px dashed #ccc');
        this.createInfoIcon(element, sourcePath, path, value);
      } else {
        this.removeInfoIcon();
      }
    }
  }

  private findPath(transformer: any, resolvedData: any, path: string): { value: any, sourcePath: string | null } {
    const pathParts = path.split('.');
    let currentTransformer = transformer;
    let sourcePath = null;

    // Find the original path in the transformer
    for (const part of pathParts) {
        if (typeof currentTransformer === 'object' && currentTransformer !== null && part in currentTransformer) {
            currentTransformer = currentTransformer[part];
            if (typeof currentTransformer === 'string') {
                sourcePath = currentTransformer;
                break;
            }
        } else {
            // Path not found in transformer
            return { value: undefined, sourcePath: null };
        }
    }

    // If we didn't find a string path, return null
    if (typeof currentTransformer !== 'string') {
        return { value: undefined, sourcePath: null };
    }

    // Now find the value in resolvedData
    const sourcePathParts = path.split('.');
    let value = resolvedData;
    for (const part of sourcePathParts) {
        if (typeof value === 'object' && value !== null && part in value) {
            value = value[part];
        } else {
            // Path not found in resolvedData
            return { value: undefined, sourcePath };
        }
    }

    return { value, sourcePath };
}

  private createInfoIcon(containerElement: any, sourcePath: string | null, path: string, value: any) {
    this.removeInfoIcon(); // Remove existing icon if any

    this.infoIcon = this.renderer.createElement('span');
    this.renderer.setProperty(this.infoIcon, 'innerHTML', '{{}}');
    this.renderer.addClass(this.infoIcon, 'info-icon');
    this.renderer.setStyle(this.infoIcon, 'cursor', 'pointer');
    this.renderer.setStyle(this.infoIcon, 'margin-left', '5px');
    this.renderer.setStyle(this.infoIcon, 'font-size', '10px');
    this.renderer.setStyle(this.infoIcon, 'color', 'grey');
    this.renderer.setStyle(this.infoIcon, 'position', 'absolute');
    this.renderer.setStyle(this.infoIcon, 'top', '0');
    this.renderer.setStyle(this.infoIcon, 'right', '0');

    this.renderer.listen(this.infoIcon, 'click', () => this.openInfoDialog(sourcePath, path, value));

    this.renderer.appendChild(containerElement, this.infoIcon);
  }

  private removeInfoIcon() {
    if (this.infoIcon && this.infoIcon.parentNode) {
      this.renderer.removeChild(this.infoIcon.parentNode, this.infoIcon);
      this.infoIcon = null;
    }
  }

  private openInfoDialog(sourcePath: string | null, path: string, value: any) {
    this.dialog.open(FieldInspectorComponent, {
      data: {
        title: sourcePath,
        path: path,
        value: value
      },
      width: '700px'
    });
  }
}