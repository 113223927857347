import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';

@Component({
  selector: 'app-legal-summary',
  templateUrl: './legal-summary.component.html',
  styleUrls: ['./legal-summary.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTableModule, MatPaginatorModule]
})
export class LegalSummaryComponent implements OnInit, AfterViewInit {
  @Input()
  activeSuits: any = {};

  @Input()
  releasedSuits: any = {};

  @Input()
  activeJudgements: any = {};

  @Input()
  printMode: boolean = false;

  @Input()
  releasedJudgements: any = {};

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();


  displayedColumns: string[] = ['date', 'filingType', 'caseNumber', 'amount', 'plaintiff'];
  dataSourceActiveSuits!: MatTableDataSource<any>;
  dataSourceReleasedSuits!: MatTableDataSource<any>;
  dataSourceActivejudgement!: MatTableDataSource<any>;
  dataSourceReleasedjudgements!: MatTableDataSource<any>;



  ngOnInit(): void {
    this.dataSourceActiveSuits = new MatTableDataSource<any>(this.activeSuits || []);
    this.dataSourceReleasedSuits = new MatTableDataSource<any>(this.releasedSuits || []);
    this.dataSourceActivejudgement = new MatTableDataSource<any>(this.activeJudgements || []);
    this.dataSourceReleasedjudgements = new MatTableDataSource<any>(this.releasedJudgements || []);

  }

  ngAfterViewInit() {
    this.dataSourceActiveSuits.paginator = this.paginator.toArray()[0];;
    this.dataSourceReleasedSuits.paginator = this.paginator.toArray()[1];;
    this.dataSourceActivejudgement.paginator = this.paginator.toArray()[2];;
    this.dataSourceReleasedjudgements.paginator = this.paginator.toArray()[3];;
  }
}

