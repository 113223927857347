import { Component, OnInit, inject } from '@angular/core';
import { NavbarComponent } from "../../shared/components/navbar/navbar.component";
import { AuthService } from '@auth0/auth0-angular';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { ApiService } from 'src/app/core/services/api.service';
import { switchMap } from 'rxjs/operators';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { FooterComponent } from "../../shared/components/footer/footer.component";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    standalone: true,
    imports: [NavbarComponent, CommonModule, MatTabsModule, MatTableModule, MatIconModule, FooterComponent, MatProgressSpinnerModule]
})
export class AdminComponent implements OnInit {
    auth = inject(AuthService);
    apiService = inject(ApiService);

    displayedColumns: string[] = ['Email', 'Type', 'Name', 'Report', 'CreateDate', 'Score', 'Status', 'UBO', 'Actions'];
    diligences = [];
    loading = false;
    currentPhase = 5;
    phases = [
        { name: 'Phase 1', value: 1 },
        { name: 'Phase 2', value: 2 },
        { name: 'Phase 3', value: 3 },
        { name: 'Phase 4', value: 4 },
        { name: 'Phase 5', value: 5 },
        { name: 'Phase 6', value: 6 }
      ];

    ngOnInit(): void {
        this.loadDiligences(this.currentPhase);
    }

    loadDiligences(phase: number = 5): void {
        this.currentPhase = phase;
        this.loading = true;
        this.auth.user$.pipe(
            switchMap(user => {
                if (user?.email) {
                    return this.apiService.getDiligencesByPhase(phase);
                } else {
                    //TODO: handle error
                    throw new Error('user not logged in');
                }
            })
        ).subscribe(
            diligences => {
                this.loading = false;
                this.diligences = diligences?.dueDiligences || [];
            }
        );
    }

    openDiligence(uuid: string): void {
        window.open(`/dueDiligence/${uuid}`, '_blank');
    }
}