import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';

@Component({
  selector: 'app-sanctions-report',
  standalone: true,
  imports: [
    CommonModule,
    ReportFieldDirective
  ],
  template: `
      <div *reportField="'sanctions.screening.companies'; let companies" class="w-full">
        <div *ngFor="let company of companies" class="w-full">
        <div class="flex w-full flex-wrap my-5">
          <div class="w-full">
            <div class="border border-grey border-t-cyan-700 w-full">
            
              <table class="w-full">
                <thead>
                  <tr class="bg-blue-950">
                    <th colspan="2" class="text-center">
                      <h1 class="text-lg p-1 text-white m-0">{{ company.name }}</h1>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="py-2 px-4 font-bold">Company Name:</td>
                    <td class="py-2 px-4">{{ company.name }}</td>
                  </tr>
                  <tr>
                    <td class="py-2 px-4 font-bold">Country:</td>
                    <td class="py-2 px-4">{{ company.country }}</td>
                  </tr>
                  <tr>
                    <td class="py-2 px-4 font-bold">Registration Number:</td>
                    <td class="py-2 px-4">{{ company.regNumber }}</td>
                  </tr>
                  <tr >
                    <td class="py-2 px-4 font-bold">Legal Form:</td>
                    <td class="py-2 px-4">{{ company.legalForm }}</td>
                  </tr>
                  <tr>
                    <td class="py-2 px-4 font-bold">Status:</td>
                    <td class="py-2 px-4">{{ company.status }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        
      

      <div class="w-full border-1 border-grey">
        <div class="mb-8 border border-grey border-t-cyan-700 w-full">
          <div class="bg-bgColor text-center">
            <h3 class="text-lg p-1 m-0">Sanctions Details</h3>
          </div>
          <div class="bg-white shadow overflow-hidden">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Authority</th>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Program</th>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Start Date</th>
                  <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">End Date</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr *ngFor="let sanction of company.sanctions">
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ sanction.authority }}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ sanction.program }}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ sanction.start_date }}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ sanction.end_date }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
      </div>

    </div>
  `
})
export class SanctionsScreeningComponent {
  @Input() printMode: boolean = false;
}