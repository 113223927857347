import { Component, inject } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { CommonModule, DatePipe } from '@angular/common';
import { EMPTY, Observable, catchError, combineLatestWith, of, take, tap } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { SearchbarComponent } from "../../shared/components/searchbar/searchbar.component";
import { SubmitParams } from 'src/app/core/models/params';
import { CompaniesGridComponent } from 'src/app/shared/components/companies-grid/companies-grid.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { PostDiligenceDialogComponent } from 'src/app/shared/components/post-diligence-dialog/post-diligence-dialog.component';
import { NavbarComponent } from 'src/app/shared/components/navbar/navbar.component';
import { MyAuthService } from 'src/app/core/services/auth.service';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FooterComponent } from "../../shared/components/footer/footer.component";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    providers: [ApiService, DatePipe],
    imports: [
        CommonModule,
        NavbarComponent,
        SearchbarComponent,
        CompaniesGridComponent,
        MatIconModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatDialogModule,
        FooterComponent
    ]
})
export class HomeComponent {
  apiService = inject(ApiService);
  dialog = inject(MatDialog);
  myAuthService = inject(MyAuthService)
  auth = inject(AuthService);
  router = inject(Router);
  params?: SubmitParams
  page = 0;
  pageSize = 10;
  companies$?: Observable<any>;
  individuals$?: Observable<any>;
  isAdmin$: Observable<boolean> = this.myAuthService.isAdmin();
  user$?: Observable<any> = this.auth.user$;

  loading = false;
  error = false;

  onSubmitSearchbar(params: SubmitParams) {
    this.params = params;
    this.resetSearchbar();
    

    if (params.isCompany) {
      this.getCompanies(0, 10);
    } else {
      this.getIndividuals(0, 10);
  }
  }

  redirectToSearch(): void {
    this.router.navigate(['/search']);
  }

  getCompanies(page: number, pageSize: number) {
    if (!this.params) return;
    this.loading = true;
    this.page = page;
    this.pageSize = pageSize;
    this.companies$ = this.apiService.getCompanies(this.params, page + 1, pageSize).pipe(
      tap(companies => {
        if (companies.companies.length === 0) {
          this.error = true
        }
        this.loading = false;
      }),
      catchError(() => {
        this.error = true;
        this.loading = false;
        return EMPTY;
      })
    )
  }

  getIndividuals(page: number, pageSize: number) {
    if (!this.params) return;
    this.loading = true;
    this.page = page;
    this.pageSize = pageSize;
    this.individuals$ = this.apiService.getIndividuals(this.params, page + 1, pageSize).pipe(
      tap(individuals => {
        if (individuals.individuals.length === 0) {
          this.error = true
        }
        this.loading = false;
      }),
      catchError(() => {
        this.error = true;
        this.loading = false;
        return EMPTY;
      })
    )
  }

  resetSearchbar() {
    this.error = false;
    this.loading = false;
    this.companies$ = undefined;
    this.individuals$ = undefined;
  }

  getDiligence(data: any) {
    this.dialog.open(PostDiligenceDialogComponent, {
      data: data,
      width: '80%', 
      height: '80%', 
      maxWidth: '100vw',
      maxHeight: '100vh'
    });
  }

  openDiligenceById(id: string) {
    this.router.navigate(['/dueDiligence', id]);
  }
}
