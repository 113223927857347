import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';

@Component({
  selector: 'app-businnes-affiliations',
  templateUrl: './businnes-affiliations.component.html',
  styleUrls: ['./businnes-affiliations.component.scss'],
  standalone: true,
  imports: [CommonModule, MatExpansionModule, ReportFieldDirective],
})
export class BusinnesAffiliationsComponent {

  @Input() printMode: boolean = false;

  getCurrentCharges(positions: any[]) {
    return positions?.filter(pos => pos?.positionType === 'Current');
  }

  getCeasedCharges(positions: any[]) {
    return positions?.filter(pos => pos?.positionType === 'Previous');
  }

  getInactiveAndPreviousCharges(previous: any[], inactive: any[]) {
    return previous?.concat(inactive);
  }
}
