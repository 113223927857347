import { Component } from '@angular/core';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-pdf-header',
    imports: [ReportFieldDirective, DatePipe],
    standalone: true,
    template: `
    <div class="w-full flex items-center justify-center my-2">
        <div class="cursor-pointer w-56 relative">
            <div class="flex items-center justify-center">
                <img src="../../../assets/TevunaLogo.png" class="w-auto h-full"/>
            </div>
            <span class="text-center absolute left-4 bottom-1 text-sky-800 text-sm xs:text-[8px] font-sans">Discover the Impossible</span>
        </div>
    </div>
`
})
export class PdfHeaderComponent {
}