import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { State, City } from 'country-state-city';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { MatSelectCountryModule, Country } from '@angular-material-extensions/select-country';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule, DatePipe } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SubmitParams } from 'src/app/core/models/params';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { MatAutocompleteModule } from '@angular/material/autocomplete';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatSelectCountryModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [DatePipe],
})
export class SearchbarComponent implements OnInit {
  formGroup!: FormGroup;
  error = false;
  states: any[] = [];
  cities: any[] = [];
  filteredStates: Observable<any[]>;
  stateFilter: FormControl;

  searchBy = 'Companies';

  @Output()
  companyParams = new EventEmitter<SubmitParams>();

  @Output()
  resetForm = new EventEmitter<boolean>();

  constructor(private datePipe: DatePipe) {
    this.filteredStates = of([]);
    this.stateFilter = new FormControl({ value: '', disabled: true });
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      'country': new FormControl(null, Validators.required),
      'query': new FormControl(null),
      'city': new FormControl({value: null, disabled: true}),
      'state': new FormControl({value: null, disabled: true}),
      'dob': new FormControl('')
      /* 'phone': new FormControl(null), */
    });

    this.formGroup.get('country')?.valueChanges.subscribe(countryCode => {
      this.onCountryChange(countryCode);
    });

    this.filteredStates = this.stateFilter.valueChanges.pipe(
      startWith(''),
      map(value => this._filterStates(value || ''))
    );

  }

  onStateSelection(state: any) {
    const stateControl = this.formGroup.get('state');
    if (stateControl) {
      stateControl.setValue(state.isoCode);
      this.stateFilter.setValue(state.name);
    }
  }

  onCountryChange(country: Country) {
    if (country?.alpha2Code) {
      this.states = State.getStatesOfCountry(country.alpha2Code);
      this.formGroup.get('state')?.enable()
      this.stateFilter.enable();
      this.formGroup.get('city')?.enable();
      this.formGroup.get('state')?.setValue(null);
      this.formGroup.get('city')?.setValue(null);
      this.cities = [];
      this.stateFilter.setValue('');
    } else {
      this.states = [];
      this.cities = [];
      this.formGroup.get('state')?.disable();
      this.formGroup.get('city')?.disable();
    }
  }

  private _filterStates(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.states.filter(state => state.name.toLowerCase().includes(filterValue));
  }

  onSubmit() {
    this.error = false;
    if (this.formGroup.valid) {
      if (this.searchBy === 'Companies') {
        this.companyParams.emit({
          isCompany: true,
          country: this.formGroup.value.country.alpha2Code,
          query: this.formGroup.value.query,
          city: this.formGroup.value.city,
          province: this.formGroup.value.state,
        });
      } else {
        const formattedDate = this.datePipe.transform(this.formGroup.value.dob, 'yyyy-MM-dd');
        this.companyParams.emit({
          isCompany: false,
          country: this.formGroup.value.country.alpha2Code,
          query: this.formGroup.value.query,
          dateOfBirth: formattedDate as string
        });
      }
  } else {
    this.error = true;
  }
  }

  reset() {
    this.error = false;
    this.formGroup.reset()
    this.resetForm.emit(true);
  }

}
