import { CommonModule } from '@angular/common';
import { Component, AfterViewInit, ElementRef, ViewChild, Renderer2, NgZone } from '@angular/core';

@Component({
    selector: 'app-tos',
    template: `
        <div #termlyEmbed name="termly-embed" data-id="fb600cf1-8731-44c5-bfcd-e066754feb39" style="min-height: 100px; border: 1px solid #ccc;">
            <p *ngIf="isLoading">Loading Termly content...</p>
            <p *ngIf="error">{{ error }}</p>
        </div>
    `,
    standalone: true,
    imports: [CommonModule]
})
export class TosComponent implements AfterViewInit {
    @ViewChild('termlyEmbed') termlyEmbed!: ElementRef;
    isLoading = true;
    error: string | null = null;

    constructor(private renderer: Renderer2, private ngZone: NgZone) {}

    ngAfterViewInit() {
        this.loadTermlyScript();
    }

    loadTermlyScript() {
        this.ngZone.runOutsideAngular(() => {
            const script = this.renderer.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://app.termly.io/embed-policy.min.js';
            script.id = 'termly-jssdk';

            script.onload = () => {
                console.log('Termly script loaded');
                this.ngZone.run(() => {
                    this.isLoading = false;
                    // Manually trigger Termly load
                    if ((window as any).Termly && typeof (window as any).Termly.load === 'function') {
                        (window as any).Termly.load('fb600cf1-8731-44c5-bfcd-e066754feb39');
                    } else {
                        console.error('Termly object not found or load method not available');
                        this.error = 'Failed to initialize Termly content.';
                    }
                });
            };

            script.onerror = (error: any) => {
                console.error('Failed to load Termly script', error);
                this.ngZone.run(() => {
                    this.isLoading = false;
                    this.error = 'Failed to load Termly content. Please try again later.';
                });
            };

            // Append the script to the document body instead of the component
            this.renderer.appendChild(document.body, script);
        });
    }
}