import { Component, Input } from '@angular/core';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';
import { CommonModule } from '@angular/common';
@Component({
    selector: 'app-sources',
    standalone: true,
    imports: [ReportFieldDirective, CommonModule],
    template: `
    <div *reportField="'tevunaReport.references'; let references">
    <div class="p-2" *ngFor="let reference of references"><a
                            href="{{reference}}">{{reference}}</a></div>
    </div>
                            `
})
export class SourcesComponent {
    @Input() printMode: boolean = false;
}