import { Component } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Tevuna';
  constructor(
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.googleAnalyticsService.pageView(event.urlAfterRedirects);
    });
  }
}
