import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportDataService {
  private _transformer: any;
  private _resolvedData: any;

  setTransformer(transformer: any) {
    this._transformer = transformer;
  }

  setResolvedData(resolvedData: any) {
    this._resolvedData = resolvedData;
  }

  get transformer() {
    return this._transformer;
  }

  get resolvedData() {
    return this._resolvedData;
  }
}