import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-payment-data',
  templateUrl: './payment-data.component.html',
  styleUrls: ['./payment-data.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class PaymentDataComponent {
  @Input()
  outstandingExperiencesInTheLast6Months: any;

  @Input() printMode: boolean = false;
  
}
