<nav class="h-24 flex items-center justify-between">
    <div class="h-full cursor-pointer min-w-36 lg:min-w-56 relative" (click)="navigateToHome()">
        <div class="h-full flex items-center justify-center">
            <img src="../../../assets/TevunaLogo.svg" class="w-auto h-full"/>
        </div>
        <span class="text-center absolute left-4 bottom-1 text-sky-800 text-sm xs:text-[8px] font-sans">Discover the Impossible</span>
    </div>

    <div class="p-3 flex justify-center items-center flex-nowrap">
        <div *ngIf="auth.user$ | async as user" class="flex justify-center items-center">
            <mat-icon>person</mat-icon>
            <div>{{user.email}} <span *ngIf="isAdmin$ | async">
                <button (click)="navigateToAdmin()" [disabled]="!(auth.user$ | async)?.email" mat-stroked-button color="primary">      
                    <span>ADMIN</span>
                    <mat-icon
                                    class="material-symbols-outlined">open_in_new</mat-icon>
                </button>
            </span></div>
        </div>
        <div class="p-3 flex flex-col items-center">
            <div *ngIf="auth.isAuthenticated$ | async; else loggedOut">
                <button mat-stroked-button color="primary" (click)="auth.logout()">
                    logout ⬅️
                </button>
            </div>
            <ng-template #loggedOut>
                <button mat-stroked-button color="primary" (click)="auth.loginWithRedirect()">
                    Log in ➡️
                </button>
            </ng-template>
        </div>

        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="navigateToMyAccount()" [disabled]="!(auth.user$ | async)?.email">
                <mat-icon>person</mat-icon>
                <span>My account</span>
            </button>
            <button mat-menu-item (click)="navigateToHome()">
                <mat-icon>home</mat-icon>
                <span>Home</span>
            </button>
        </mat-menu>
    </div>
</nav>