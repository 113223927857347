<ng-container *ngIf="!packageChosen" class="max-h-full">
  <h2 mat-dialog-title>Choose your package</h2>
  <mat-dialog-content>
    <ng-container>
      <div class="flex flex-col md:flex-row lg:p-5 lg:justify-around space-x-4 ">

        <ng-container *ngFor="let package of packages">
          <div class="flex bg-gray-50 border rounded-lg overflow-hidden shadow-xl my-2 md:w-1/3">
            <div class="flex flex-col justify-between w-full">
              <div class="p-6">
                <div class="text-h2 font-semibold text-gray-700 py-1 text-center md:text-left h-10">{{ package.name }}</div>
                <div class="mt-6 w-full">
                  <div class="text-black text-xl font-normal py-2 w-full">${{ package.price }}</div>
                </div>
                <ul class="min-h-72">
                  <ng-container *ngFor="let feature of package.features">
                    <li class="mt-4 text-sm text-primary text-center md:text-left">{{ feature }}</li>
                  </ng-container>
                </ul>
              </div>
              <div class="mt-6 w-full">
                <button class="bg-primary text-white py-2 px-4 hover:bg-primary-dark w-full"
                  (click)="setPackage(package)">Choose</button>
              </div>
            </div>
          </div>
        </ng-container>


      </div>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
</ng-container>

<ng-container *ngIf="packageChosen">
  <h2 mat-dialog-title>Checkout</h2>
  <mat-dialog-content>
    <div class="flex flex-col lg:flex-row">
      <div class="lg:w-1/2 h-auto bg-gray-100 flex flex-col items-center p-2 lg:p-10">
        <div class="flex flex-col bg-white rounded-lg shadow-lg p-6 mt-5 w-full">
          <div class="flex justify-between w-full">
            <div>
              <div>
                <span>{{ data.type === 'COMPANY' ? 'Company' : 'Name' }}</span>
                <h2 class="text-2xl font-semibold text-primary text-center md:text-left">{{data.name}}</h2>
              </div>
            </div>
          </div>
          <div class="flex w-full mt-10">
            <div>
              <span>Package</span>
              <h2 class="text-2xl font-semibold text-primary text-center md:text-left">{{packageChosen.name}}</h2>
            </div>
            <div class="ml-auto">
              <span class="font-bold text-black">${{packageChosen.price}}</span>
            </div>
          </div>
        </div>
        <div class="flex items-center p-4 border border-black rounded-lg w-full mt-10" *ngIf="data.type === 'COMPANY' && packageChosen.ubo_add_on">
          <div class="ml-4 flex flex-col">
            <div class="flex mb-2 items-center -ml-3">
              <mat-checkbox 
                class="mr-1" 
                color="primary"
                [(ngModel)]="ubo_selected" 
                (change)="onCheckboxChange($event)">
              </mat-checkbox>
              <span class="font-bold m-0 text-black">UBO discovery</span>
              <div class="font-bold text-black ml-2 ml-auto">${{ packageChosen.ubo_add_on }}</div>
            </div>
            <span class="m-0 text-[12px]">Identifying the Ultimate Beneficial Owner (UBO) is crucial for enforcing a
              judgment to comply with your Due Diligence policies. We reconstruct the chain of control to identify the
              ultimate beneficial owners of companies, highlighting critical issues of entities involved.</span>
          </div>          
        </div>
        <hr/>
        <div class="flex flex-col text-center justify-center align-center mt-20 w-full bg-gray-200 p-2">
          <div>
            <span>Total Due</span>
          </div>
          <div>
            <span class="font-bold text-black">${{this.formGroup.getRawValue().xAmount}}</span>
          </div>
        </div>

      </div>
      <div class="lg:w-1/2">
        <form [formGroup]="formGroup" (ngSubmit)="onPaySubmit()">
          <div class="flex flex-col m-4">
            <p class="mt-20 text-2xl font-bold">Payment</p>
            <mat-form-field appearance="outline">
              <mat-label>Card Number</mat-label>
              <input matInput formControlName="xCardNum">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Expiration Date (MM/YY):</mat-label>
              <input appExpiryDateFormatter matInput formControlName="xExp">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>CVV</mat-label>
              <input matInput formControlName="xCVV">
            </mat-form-field>
            <p class="mt-20 text-2xl font-bold">Billing Info</p>
            <mat-form-field class="companyNameForm" appearance="outline">
              <mat-label>First Name:</mat-label>
              <input matInput formControlName="xBillFirstName">
            </mat-form-field>
            <mat-form-field class="companyNameForm" appearance="outline">
              <mat-label>Last Name:</mat-label>
              <input matInput formControlName="xBillLastName">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Street:</mat-label>
              <input matInput formControlName="xBillStreet">
            </mat-form-field> <mat-form-field appearance="outline">
              <mat-label>City:</mat-label>
              <input matInput formControlName="xBillCity">
            </mat-form-field> <mat-form-field appearance="outline">
              <mat-label>State:</mat-label>
              <input matInput formControlName="xBillState">
            </mat-form-field> <mat-form-field appearance="outline">
              <mat-label>Zip:</mat-label>
              <input matInput formControlName="xBillZip">
            </mat-form-field> <mat-form-field appearance="outline">
              <mat-label>Mobile:</mat-label>
              <input matInput formControlName="xBillMobile">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Amount</mat-label>
              <input matInput formControlName="xAmount">
            </mat-form-field>

            <div class="flex flex-col m-4">
              <mat-checkbox color="primary" formControlName="termsAccepted">
                I accept the <a href="javascript:void(0);" class="underline text-blue-600" (click)="openTermsAndConditions()">Terms Of Sale</a>
              </mat-checkbox>
              <mat-error *ngIf="formControls['termsAccepted'].touched && formControls['termsAccepted'].errors?.['required']">
                You must accept the Terms Of Sale to proceed.
              </mat-error>
            </div>
            <button mat-raised-button type="submit" class="text-center">Pay and Submit</button>
          </div>
        </form>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
</ng-container>

<div class="flex justify-center pt-5">
  <mat-spinner *ngIf="loading"></mat-spinner>
</div>

<div class="successContainer" *ngIf="this.userHasPayed">
  <mat-icon class="text-green-500">check_circle</mat-icon>
  <span class="text-green-500">Transaction approved successfully!</span>
</div>

<div *ngIf="this.error">
  <mat-icon class="text-red">check_circle</mat-icon>
  <span class="text-red">We were unable to process your payment. Please verify your payment details and try again.
    If
    the problem persists, consider using an alternative payment method or contact our support team for
    assistance.</span>
</div>