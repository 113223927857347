import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5/dist/esm';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  constructor() { }

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = '',
    eventValue: number = 0 
  ) {
    gtag('event', eventName, {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventValue: eventValue
    });
  }

  public pageView(url: string) {
    gtag('config', 'G-G8E77RMSQW', {
      page_path: url
    });
  }

  public setUserId(userId: string) {
    gtag('config', 'G-G8E77RMSQW', {
      'user_id': userId
    });
  }

  public setUserProperties(email: string) {
    const hashedEmail = Md5.hashStr(email);
    gtag('set', 'user_properties', {
      'hashed_email': hashedEmail
    });
  }
}