import { Component, Input } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';

@Component({
    selector: 'app-director-details',
    standalone: true,
    imports: [ReportFieldDirective, CommonModule, DatePipe],
    template: `
    <div *reportField="'directorDetails.name'"
     class="border-2 border-grey p-5 w-full">
  <table>
    <tr>
      <th>
        <h1 class="px-4" *reportField="'directorDetails.name'; let value">{{value}}</h1>
      </th>
    </tr>
    <tr *reportField="'directorDetails.address.simpleValue'; let address">
      <td class="py-2 px-4 font-bold">Address:</td>
      <td>{{address}}</td>
    </tr>
    <tr *reportField="'businessAffiliations.current.positions.positionName'; let positionName">
      <td class="py-2 px-4 font-bold">Function:</td>
      <td>{{positionName}}</td>
    </tr>
    <tr *reportField="'directorDetails.dateOfBirth'; let value">
      <td class="py-2 px-4 font-bold">Date of Birth:</td>
      <td *ngIf="value">{{value | date}}</td>
    </tr>
    <tr *reportField="'directorDetails.gender'; let value">
      <td class="py-2 px-4 font-bold">Gender:</td>
      <td>{{value}}</td>
    </tr>
    <tr *reportField="'directorDetails.addressOfBirth.city'; let value">
      <td class="py-2 px-4 font-bold">Municipality of Birth:</td>
      <td>{{value}}</td>
    </tr>
    <tr *reportField="'directorDetails.addressOfBirth.province'; let value">
      <td class="py-2 px-4 font-bold">Province of Birth:</td>
      <td>{{value}}</td>
    </tr>
    <tr *reportField="'directorDetails.addressOfBirth.id'; let value">
      <td class="py-2 px-4 font-bold">Tax Code:</td>
      <td>{{value}}</td>
    </tr>
  </table>
</div>
`
})
export class DirectorDetailsComponent {}