<div class="border-2 border-grey border-t-cyan-700 m-5">
    <h1>Payment Data</h1>
    <table class="m-4">
        <tr class="my-6">
            <td class="pr-4"><b>Balance</b></td>
            <td>€{{outstandingExperiencesInTheLast6Months?.balance}}</td>
        </tr>
        <tr class="my-6">
            <td class="pr-4"><b>Within Terms</b></td>
            <td>€{{outstandingExperiencesInTheLast6Months?.withinTerms}}</td>
        </tr>
        <tr class="my-6">
            <td class="pr-4"><b>1 to 30</b></td>
            <td>€{{outstandingExperiencesInTheLast6Months?.range1to30}}</td>
        </tr>
        <tr class="my-6">
            <td class="pr-4"><b>31 to 60</b></td>
            <td>€{{outstandingExperiencesInTheLast6Months?.range31to60}}</td>
        </tr>
        <tr class="my-6">
            <td class="pr-4"><b>61 to 90</b></td>
            <td>€{{outstandingExperiencesInTheLast6Months?.range61to90}}</td>
        </tr>
        <tr class="my-6">
            <td class="pr-4"><b>91+</b></td>
            <td>€{{outstandingExperiencesInTheLast6Months?.range91Plus}}</td>
        </tr>
    </table>
</div>

