import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root'
  })


export class AdminInspectService {
    private showInspect = new BehaviorSubject<boolean>(false);
    showInspect$ = this.showInspect.asObservable();

    toggleInspect() {
        this.showInspect.next(!this.showInspect.value);
    }

    setInspect(show: boolean) {
        this.showInspect.next(show);
      }

    
    getInspectValue(): boolean {
      return this.showInspect.value;
    }

    


}