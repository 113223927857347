// field-info-dialog.component.ts
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-field-inspector',
  standalone: true,
  imports: [
    MatDialogModule
  ],
  template: `
    <mat-dialog-content>
      <p class="font-bold">Original Path</p>
      <code class='bg-gray-50 font-mono'>{{data.title}}</code>
      <p class="font-bold">Transformer Path</p>
      <code class='bg-gray-50 font-mono'>{{data.path}}</code>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Close</button>
    </mat-dialog-actions>
  `
})
export class FieldInspectorComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {value: any, title: string, path: string}) {}
}