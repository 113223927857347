import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportFieldDirective } from 'src/app/core/directives/report-field.directive';
import { MarkdownComponent, MarkdownService, MarkdownModule } from 'ngx-markdown';


@Component({
  selector: 'app-summary',
  standalone: true,
  providers: [MarkdownService],
  imports: [
    CommonModule,
    MarkdownComponent,
    ReportFieldDirective
  ],
  template: `
    <div class="flex w-full flex-wrap my-5">
      <div class="w-full mb-4">
      <div class="border border-grey border-t-cyan-700 w-full">
        <table class="w-full">
          <thead>
            <tr class="bg-blue-950">
              <th colspan="2" class="text-center">
                <h1 class="text-lg p-1 text-white m-0">Key Information</h1>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *reportField="'generalInfo.companyName'; let companyName">
              <td class="py-2 px-4 font-bold">Company Name:</td>
              <td class="py-2 px-4">{{ companyName }}</td>
            </tr>
            <tr *reportField="'generalInfo.monitoredEntities'; let monitoredEntities">
              <td class="py-2 px-4 font-bold">Monitored Entities:</td>
              <td class="py-2 px-4">{{ monitoredEntities.join(', ') }}</td>
            </tr>
            <tr *reportField="'generalInfo.vatNumber', let vatNumber">
              <td class="py-2 px-4 font-bold">VAT Number:</td>
              <td class="py-2 px-4">{{ vatNumber }}</td>
            </tr>
            <tr *reportField="'generalInfo.registrationNumber'; let registrationNumber">
                    <td class="py-2 px-4 font-bold">Registration Number:</td>
                    <td class="py-2 px-4">{{ registrationNumber }}
                    </td>
              </tr>
              <tr *reportField="'generalInfo.incorporationDate'; let incorporationDate">
                  <td class="py-2 px-4 font-bold">Incorporation Date:</td>
                  <td class="py-2 px-4">{{ incorporationDate |
                      date }}</td>
              </tr>
              <tr *reportField="'generalInfo.legalForm'; let legalForm">
                  <td class="py-2 px-4 font-bold">Legal Form:</td>
                  <td class="py-2 px-4">{{ legalForm }}</td>
              </tr>
              <tr *reportField="'generalInfo.taxCode'; let taxCode">
                  <td class="py-2 px-4 font-bold">Tax Code:</td>
                  <td class="py-2 px-4">{{ taxCode }}</td>
              </tr>
              <tr *reportField="'generalInfo.otherLegalName'; let value">
                  <td class="py-2 px-4 font-bold">Other Legal Name:</td>
                  <td class="py-2 px-4">{{
                    value
                      }}</td>
              </tr>
              <tr *reportField="'generalInfo.charterNumber'; let value">
                  <td class="py-2 px-4 font-bold">Charter Number:</td>
                  <td class="py-2 px-4">{{
                    value
                      }}</td>
              </tr>
              <tr *reportField="'generalInfo.establishmentDate'; let value">
                  <td class="py-2 px-4 font-bold">Establishment Date:</td>
                  <td class="py-2 px-4">{{
                    value
                      | date }}</td>
              </tr>
              <tr
                  *reportField="'generalInfo.companyType'; let value">
                  <td class="py-2 px-4 font-bold">Company Type:</td>
                  <td class="py-2 px-4">{{
                    value }}
                  </td>
              </tr>
              <tr *reportField="'generalInfo.sicDescription'; let value">
                  <td class="py-2 px-4 font-bold">SIC Description:</td>
                  <td class="py-2 px-4">{{
                    value
                      }}</td>
              </tr>
          </tbody>
        </table>
      </div>
      </div>
      <div class="w-full border-1 border-grey px-2" *reportField="'tevunaReport.critIssueSummary'; let criticalIssueSummary">
        <h1 class="text-3xl pb-5 font-extrabold">Critical Issues Summary</h1>
      <markdown *ngIf="criticalIssueSummary" [data]="convertToMarkdown(criticalIssueSummary)"></markdown>
      </div>
    </div>
  `
})
export class SummaryComponent {
  @Input() printMode: boolean = false;

  convertToMarkdown(text: string): string {
    return text.split('\n').map(line => {
      if (line.startsWith('**') && line.endsWith('**')) {
        return '## ' + line.replace(/\*\*/g, '');
      }
      return line;
    }).join('\n\n');
  }
}