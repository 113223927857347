<div class="min-h-full">
    <app-navbar></app-navbar>

    <app-searchbar (companyParams)="onSubmitSearchbar($event)" (resetForm)="resetSearchbar()"></app-searchbar>


    <app-companies-grid *ngIf="((companies$ | async)?.companies || (individuals$ | async)?.individuals)"
        [companies]="(companies$ | async)?.companies" [individuals]="(individuals$ | async)?.individuals"
        (requestDiligence)="getDiligence($event)" (requestDiligenceById)="openDiligenceById($event?.jobId)"
        (getCompanies)="getCompanies($event.pageIndex, $event.pageSize)"
        (getIndividuals)="getIndividuals($event.pageIndex, $event.pageSize)" [isAdmin]="isAdmin$ | async"
        [email]="(user$ | async)?.email" [page]="page" [pageSize]="pageSize"></app-companies-grid>

    <div class="flex justify-center pt-5">
        <mat-spinner *ngIf="loading"></mat-spinner>
    </div>

    <div class="flex justify-center p-12 font-bold" *ngIf="error">
        <div class="flex flex-col items-center justify-center text-center p-4 border p-4 rounded border-2">
             <mat-icon color="warn">error</mat-icon>
            <h1 class="text-2xl font-extrabold mb-4">No Results Found</h1>
            <p class="text-gray-600 mb-8 lg:mx-48">
              No results found in official databases. However, our advanced AI can assist you with a thorough search. Please complete the form below to initiate a detailed investigation.
            </p>
            <button mat-button class="bg-secondary text-white rounded px-2 py-1" (click)="redirectToSearch()">
                Get Started
            </button>
          </div>
    </div>
</div>


<app-footer></app-footer>