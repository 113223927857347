import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkdownComponent, MarkdownService } from 'ngx-markdown';

@Component({
    selector: 'app-media-intelligence',
    providers: [MarkdownService],
    imports: [
        MarkdownComponent,
        CommonModule
    ],
    template: `
        <div class="p-4">
            <markdown *ngIf="critIssueDetails" [data]="convertToMarkdown(critIssueDetails)"></markdown>
        </div>
    `,
    standalone: true
})
export class MediaIntelligenceComponent {
    @Input() critIssueDetails: string = '';
    @Input() printMode: boolean = false;

    convertToMarkdown(text: string): string {
        return text.split('\n').map(line => {
          if (line.startsWith('**') && line.endsWith('**')) {
            return '## ' + line.replace(/\*\*/g, '');
          }
          return line;
        }).join('\n\n');
      }
}