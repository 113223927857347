<div class="min-h-full">
    <app-navbar></app-navbar>
    <div class="border-2 border-grey border-t-cyan-700 m-5 p-5">
        <h1>All Reports</h1>

        
        <div class="flex space-x-4 mb-6">
            <button *ngFor="let phase of phases"
                    (click)="loadDiligences(phase.value)"
                    [ngClass]="{
                      'bg-blue-600 text-white': phase.value === currentPhase,
                      'bg-gray-200 text-gray-700 hover:bg-gray-300': phase.value !== currentPhase
                    }"
                    class="px-4 py-2 rounded-md font-medium transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
              {{ phase.name }}
            </button>
          </div>

        <mat-tab-group>
            <mat-tab label="All reports">
                <div class="flex justify-center pt-5">
                    <mat-spinner *ngIf="loading"></mat-spinner>
                </div>
                <mat-table [dataSource]="diligences" class="mat-elevation-z8">
                    <ng-container matColumnDef="Email">
                        <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                        <mat-cell *matCellDef="let diligence"> {{ diligence.userEmail }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Type">
                        <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                        <mat-cell *matCellDef="let diligence"> {{ diligence.type }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Report">
                        <mat-header-cell *matHeaderCellDef> Report </mat-header-cell>
                        <mat-cell *matCellDef="let diligence"> {{ diligence.report }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Name">
                        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                        <mat-cell *matCellDef="let diligence"> {{ diligence.name }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="CreateDate">
                        <mat-header-cell *matHeaderCellDef> Create Date </mat-header-cell>
                        <mat-cell *matCellDef="let diligence"> {{ diligence.updatedAt | date }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Score">
                        <mat-header-cell *matHeaderCellDef> Score </mat-header-cell>
                        <mat-cell *matCellDef="let diligence"> {{ diligence.repScore }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Status">
                        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                        <mat-cell *matCellDef="let diligence"> {{ diligence.status }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="UBO">
                        <mat-header-cell *matHeaderCellDef> UBO </mat-header-cell>
                        <mat-cell *matCellDef="let diligence">
                            <mat-icon *ngIf="diligence.ubo">check</mat-icon>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Actions">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let diligence"> 
                            <button (click)="openDiligence(diligence.uuid)">
                                <mat-icon class="material-symbols-outlined">open_in_new</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<app-footer></app-footer>
