<div class="border-2 border-grey border-t-cyan-700 m-5">
  <div class="bg-blue-950 text-white text-center">
    <h1 class="w-full m-0 p-1">Summary</h1>
  </div>
  <div class="flex w-full">
    <div class="grid grid-cols-12 gap-2 p-4 w-1/2">
      <div class="col-span-6  text-center"></div>
      <div class="col-span-2  text-center font-bold">Total</div>
      <div class="col-span-2  text-center font-bold">Active</div>
      <div class="col-span-2  text-center font-bold">Released</div>

      <div class="col-span-6   text-center font-bold">Number Of Suits</div>
      <div class="col-span-2   text-center">{{activeSuits.length + releasedSuits.length}}</div>
      <div class="col-span-2   text-center">{{activeSuits.length}}</div>
      <div class="col-span-2   text-center">{{releasedSuits.length}}</div>
    </div>

    <div class="grid grid-cols-12 gap-2 p-4 w-1/2">
      <div class="col-span-6  text-center"></div>
      <div class="col-span-2  text-center font-bold">Total</div>
      <div class="col-span-2  text-center font-bold">Active</div>
      <div class="col-span-2  text-center font-bold ">Released</div>

      <div class="col-span-6   text-center font-bold">Number Of Judgements</div>
      <div class="col-span-2   text-center">{{activeJudgements.length + releasedJudgements.length}}</div>
      <div class="col-span-2   text-center">{{activeJudgements.length}}</div>
      <div class="col-span-2   text-center">{{releasedJudgements.length}}</div>
    </div>
  </div>
</div>

<div class="border-2 border-grey border-t-cyan-700 m-5">
  <div class="bg-blue-950 text-white text-center">
    <h1 class="w-full m-0 p-1">Active suits</h1>
  </div>
  <div [class.mat-elevation-z8]="!printMode">
    <table mat-table [ngClass]="{'mat-table': !printMode}" [dataSource]="dataSourceActiveSuits">
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Date Filed </th>
        <td mat-cell *matCellDef="let element"> {{element.filedDate | date}} </td>
      </ng-container>

      <ng-container matColumnDef="filingType">
        <th mat-header-cell *matHeaderCellDef> Filing Type </th>
        <td mat-cell *matCellDef="let element"> {{element.filingType}} </td>
      </ng-container>

      <ng-container matColumnDef="caseNumber">
        <th mat-header-cell *matHeaderCellDef> Case Number </th>
        <td mat-cell *matCellDef="let element"> {{element.filingNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef> Amount </th>
        <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
      </ng-container>

      <ng-container matColumnDef="plaintiff">
        <th mat-header-cell *matHeaderCellDef> Plaintiff </th>
        <td mat-cell *matCellDef="let element"> {{element.plaintiff}} </td>
      </ng-container>

      <tr [ngClass]="{'mat-header-row': !printMode}" *matHeaderRowDef="displayedColumns"></tr>
      <tr [ngClass]="{'mat-row': !printMode}" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator *ngIf="!printMode" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons 
      aria-label="Select page of periodic elements" #activeSuitsPaginator>
    </mat-paginator>
  </div>
</div>

<div class="border-2 border-grey border-t-cyan-700 m-5">
  <div class="bg-blue-950 text-white text-center">
    <h1 class="w-full m-0 p-1">Released suits</h1>
  </div>
  <div [class.mat-elevation-z8]="!printMode">
    <table mat-table [ngClass]="{'mat-table': !printMode}" [dataSource]="dataSourceReleasedSuits">
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Date Filed </th>
        <td mat-cell *matCellDef="let element"> {{element.filedDate | date}} </td>
      </ng-container>

      <ng-container matColumnDef="filingType">
        <th mat-header-cell *matHeaderCellDef> Filing Type </th>
        <td mat-cell *matCellDef="let element"> {{element.filingType}} </td>
      </ng-container>

      <ng-container matColumnDef="caseNumber">
        <th mat-header-cell *matHeaderCellDef> Case Number </th>
        <td mat-cell *matCellDef="let element"> {{element.filingNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef> Amount </th>
        <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
      </ng-container>

      <ng-container matColumnDef="plaintiff">
        <th mat-header-cell *matHeaderCellDef> Plaintiff </th>
        <td mat-cell *matCellDef="let element"> {{element.plaintiff}} </td>
      </ng-container>

      <tr [ngClass]="{'mat-header-row': !printMode}" *matHeaderRowDef="displayedColumns"></tr>
      <tr [ngClass]="{'mat-row': !printMode}" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator *ngIf="!printMode" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons 
      aria-label="Select page of periodic elements" #releasedSuitsPaginator>
    </mat-paginator>
  </div>
</div>

<div class="border-2 border-grey border-t-cyan-700 m-5">
  <div class="bg-blue-950 text-white text-center">
    <h1 class="w-full m-0 p-1">Active Judgements</h1>
  </div>
  <div [class.mat-elevation-z8]="!printMode">
    <table mat-table [ngClass]="{'mat-table': !printMode}" [dataSource]="dataSourceActivejudgement">
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Date Filed </th>
        <td mat-cell *matCellDef="let element"> {{element.filedDate | date}} </td>
      </ng-container>

      <ng-container matColumnDef="filingType">
        <th mat-header-cell *matHeaderCellDef> Filing Type </th>
        <td mat-cell *matCellDef="let element"> {{element.filingType}} </td>
      </ng-container>

      <ng-container matColumnDef="caseNumber">
        <th mat-header-cell *matHeaderCellDef> Case Number </th>
        <td mat-cell *matCellDef="let element"> {{element.filingNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef> Amount </th>
        <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
      </ng-container>

      <ng-container matColumnDef="plaintiff">
        <th mat-header-cell *matHeaderCellDef> Plaintiff </th>
        <td mat-cell *matCellDef="let element"> {{element.plaintiff}} </td>
      </ng-container>

      <tr [ngClass]="{'mat-header-row': !printMode}" *matHeaderRowDef="displayedColumns"></tr>
      <tr [ngClass]="{'mat-row': !printMode}" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator *ngIf="!printMode" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons 
      aria-label="Select page of periodic elements" #activeJudgementsPaginator>
    </mat-paginator>
  </div>
</div>

<div class="border-2 border-grey border-t-cyan-700 m-5">
  <div class="bg-blue-950 text-white text-center">
    <h1 class="w-full m-0 p-1">Released Judgements</h1>
  </div>
  <div [class.mat-elevation-z8]="!printMode">
    <table mat-table [ngClass]="{'mat-table': !printMode}" [dataSource]="dataSourceReleasedjudgements">
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Date Filed </th>
        <td mat-cell *matCellDef="let element"> {{element.filedDate | date}} </td>
      </ng-container>

      <ng-container matColumnDef="filingType">
        <th mat-header-cell *matHeaderCellDef> Filing Type </th>
        <td mat-cell *matCellDef="let element"> {{element.filingType}} </td>
      </ng-container>

      <ng-container matColumnDef="caseNumber">
        <th mat-header-cell *matHeaderCellDef> Case Number </th>
        <td mat-cell *matCellDef="let element"> {{element.filingNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef> Amount </th>
        <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
      </ng-container>

      <ng-container matColumnDef="plaintiff">
        <th mat-header-cell *matHeaderCellDef> Plaintiff </th>
        <td mat-cell *matCellDef="let element"> {{element.plaintiff}} </td>
      </ng-container>

      <tr [ngClass]="{'mat-header-row': !printMode}" *matHeaderRowDef="displayedColumns"></tr>
      <tr [ngClass]="{'mat-row': !printMode}" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator *ngIf="!printMode" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons 
      aria-label="Select page of periodic elements" #releasedJudgementsPaginator>
    </mat-paginator>
  </div>
</div>