<div class="flex items-center flex-col pt-8 bg-bgColor">
    <div class="formHeader">
        <h1 class="font-poppins text-h1 font-extrabold text-primary uppercase">Search by: </h1>
        <mat-form-field>
            <mat-select [(value)]="searchBy" (selectionChange)="reset()">
                <mat-option value="Companies">Companies</mat-option>
                <mat-option value="Individuals">Individuals</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div class="formContainer">
            <div class="firstLineContainer">
                <mat-select-country appearance="outline" label="Country" class="className" formControlName="country">
                </mat-select-country>
                <mat-form-field class="companyNameForm" appearance="outline">
                    <mat-label *ngIf="searchBy==='Companies'">Company name / V number</mat-label>
                    <mat-label *ngIf="searchBy==='Individuals'">Individual name</mat-label>
                    <input matInput formControlName="query">
                </mat-form-field>
            </div>
            <div class="secondLineContainer">
                <mat-form-field class="secondLineForm" appearance="outline" *ngIf="searchBy==='Companies'">
                    <mat-label>State</mat-label>
                    <input type="text" matInput [formControl]="stateFilter" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let state of filteredStates | async" [value]="state.name" (onSelectionChange)="onStateSelection(state)">
                        {{state.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field class="secondLineForm" appearance="outline" *ngIf="searchBy==='Companies'">
                    <mat-label>City</mat-label>
                    <input matInput formControlName="city">
                </mat-form-field>
                <mat-form-field *ngIf="searchBy==='Individuals'">
                    <mat-label>Date of birth</mat-label>
                    <input matInput formControlName="dob" [matDatepicker]="picker">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <mat-error class="text-center" *ngIf="error">
            Please select a country.
        </mat-error>

        <div class="buttonContainer">
            <button  class="btn cursor-pointer w-[139px] mt-[6px] text-center font-poppins text-base font-semibold bg-secondary text-white px-[5px] py-[6px] leading-8" type="reset" (click)="reset()">Reset</button>
            <button class="btn cursor-pointer w-[139px] mt-[6px] text-center font-poppins text-base font-semibold bg-primary text-white px-[5px] py-[6px] leading-8" type="submit">Search</button>
        </div>
    </form>
</div>