import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkdownComponent, MarkdownService } from 'ngx-markdown';

@Component({
    selector: 'app-disclaimer',
    providers: [MarkdownService],
    imports: [
        MarkdownComponent,
        CommonModule
    ],
    template: `
        <div class="p-4">
            <markdown [data]="convertToMarkdown(disclaimerText)"></markdown>
        </div>
    `,
    standalone: true
})
export class DisclaimerComponent {
    @Input() critIssueDetails: string = '';
    @Input() printMode: boolean = false;

    disclaimerText: string = `
    **Disclaimer**
    The information provided by Tevuna Insights, LLC (“Tevuna”) has been obtained from sources that Tevuna believes to be reliable.  Tevuna has not, however, verified its accuracy and makes no guarantee, warranty or representation about such information.  All information provided by Tevuna is provided subject to any and all errors, and/or omissions.

    Due to the possibility of multiple entities or individuals with similar names (homonyms), there may be discrepancies or inconsistencies in the results. The process of disambiguating these entries can lead to errors or inaccuracies in the report. We recommend reviewing all findings carefully and cross-referencing with additional information when necessary.

    **No Representations and Warranties**
    Notwithstanding Tevuna’s attempts and efforts to provide accurate information, Tevuna assumes no responsibility for, and makes no representations with respect to, the accuracy or completeness of any information or material presented or provided to any of its clients.  All information and material is provided “AS IS”, and TEVUNA EXPRESSLY DISCLAIMS AND EXPRESS OR IMPLIED WARRANTIES OR MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RELATING TO SUCH INFORMATION AND MATERIAL. IN NO EVENT WHATSOEVER SHALL TEVUNA BE LIABLE FOR ANY DAMAGES OF ANY KIND WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DIRECT, SPECIAL, INDIRECT, CONSEQUENTIAL OR INCIDENTAL DAMAGES, DAMAGES RESULTING FROM THE USE OF OR RELIANCE ON INFORMATION OR MATERIAL PROVIDED, OR DAMAGES FOR LOST PROFITS OR LOST REVENUES.
    `;

    convertToMarkdown(text: string): string {
        return text.split('\n').map(line => {
          if (line.startsWith('**') && line.endsWith('**')) {
            return '## ' + line.replace(/\*\*/g, '');
          }
          return line;
        }).join('\n\n');
      }
}
