<div class="tableContainer" *ngIf="companies?.length">
    <h1>Results List</h1>
    <mat-table multiTemplateDataRows [dataSource]="companies">


        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Company Name </mat-header-cell>
            <mat-cell *matCellDef="let company"> {{ company.name }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="regNo">
            <mat-header-cell *matHeaderCellDef> Company Number </mat-header-cell>
            <mat-cell *matCellDef="let company"> {{ company?.regNo }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
            <mat-header-cell *matHeaderCellDef> V number </mat-header-cell>
            <mat-cell *matCellDef="let company"> {{ company.safeNo }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="address">
            <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
            <mat-cell *matCellDef="let company"> {{ company.address.simpleValue }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="locationType">
            <mat-header-cell *matHeaderCellDef> Location Type </mat-header-cell>
            <mat-cell *matCellDef="let company"> {{ company.officeType }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="companyStatus">
            <mat-header-cell *matHeaderCellDef> Company Status </mat-header-cell>
            <mat-cell *matCellDef="let company"> {{ company.status }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expand">
            <mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</mat-header-cell>
            <mat-cell *matCellDef="let company">
                <button mat-button color="primary" (click)="getDiligence(company, 'COMPANY')">
                    Get Report
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columnsToDisplayWithExpand"></mat-row>
    </mat-table>
    <div class="flex justify-left items-center border-t border-b border-gray-300 p-4">
        <span class="text-black">
            <strong>Can’t find what you’re looking for?</strong> provide us the details and our advanced AI will
            find
            it.
        </span>
        <button mat-button class="bg-blue-500 text-white rounded px-2 py-1 ml-8" (click)="redirectToSearch()">
            Get Started
        </button>
    </div>
</div>
<mat-paginator [length]="100" [pageSize]="pageSize" [pageIndex]="page" [pageSizeOptions]="[5, 10, 25, 100]"
    (page)="this.getCompanies.emit($event)" *ngIf="companies?.length || 0 > pageSize" aria-label="Select page">
</mat-paginator>

<div class="tableContainer" *ngIf="individuals?.length">
    <h1>Results List</h1>
    <mat-table [dataSource]="individuals" multiTemplateDataRows class="mat-elevation-z8">

        <ng-container matColumnDef="firstName">
            <mat-header-cell *matHeaderCellDef> First Name </mat-header-cell>
            <mat-cell *matCellDef="let individual"> {{ individual.firstName }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastName">
            <mat-header-cell *matHeaderCellDef> Last Name </mat-header-cell>
            <mat-cell *matCellDef="let individual"> {{ individual.lastName }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="country">
            <mat-header-cell *matHeaderCellDef> Country </mat-header-cell>
            <mat-cell *matCellDef="let individual"> {{ individual.country }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="company">
            <mat-header-cell *matHeaderCellDef> Company </mat-header-cell>
            <mat-cell *matCellDef="let individual"> {{ individual.company?.companyName }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="address">
            <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
            <mat-cell *matCellDef="let individual"> {{ individual.address.simpleValue }} </mat-cell>
        </ng-container>

        <!-- Company Number Column -->
        <ng-container matColumnDef="number">
            <mat-header-cell *matHeaderCellDef> V number </mat-header-cell>
            <mat-cell *matCellDef="let individual"> {{ individual.peopleId }} </mat-cell>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="expand">
            <mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</mat-header-cell>
            <mat-cell *matCellDef="let company">
                <button mat-button color="primary" (click)="getDiligence(company, 'INDIVIDUAL')">
                    Get Report
                </button>
            </mat-cell>
        </ng-container>


        <!-- Define the table rows with the columns -->
        <mat-header-row *matHeaderRowDef="columnsToDisplayWithExpandIndividuals"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columnsToDisplayWithExpandIndividuals"></mat-row>

    </mat-table>
    <div class="flex justify-left items-center border-t border-b border-gray-300 p-4">
        <span class="text-black">
            <strong>Can’t find what you’re looking for?</strong> provide us the details and our advanced AI will
            find
            it.
        </span>
        <button mat-button class="bg-blue-500 text-white rounded px-2 py-1 ml-8" (click)="redirectToSearch()">
            Get Started
        </button>
    </div>
    <mat-paginator [length]="100" [pageSize]="pageSize" [pageIndex]="page" [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="this.getIndividuals.emit($event)" *ngIf="companies?.length || 0 > pageSize" aria-label="Select page">
    </mat-paginator>
</div>